import {IBreadCrumb} from './../../../../models/entities/IBreadCrumb';
import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {navItems} from '../../../_nav';
import {AuthService} from '../../../../shared/authService';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DataService} from "../../../../shared/dataService";
import {HeaderService} from "../../../../shared/header.service";
import {RedeemCodeDialogComponent} from "../dialogs/dialog-redeem-code/redeem-code-dialog.component";
import {EDialogService} from "../../../../shared/dialogService";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['/default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;

  constructor(public authService: AuthService,
              public dataService: DataService,
              private activatedRoute: ActivatedRoute,
              private _dialogService: EDialogService,
              public _headerService: HeaderService,
              private dialog: MatDialog,
              public router: Router) {

    router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this._headerService.triggerBalanceRefresh.next();
          this.authService.triggerRoleRefresh.next();
        }
      }
    );
  }

  ngOnInit(): void {
    this.authService.ReloadUser();
    this._headerService.triggerBalanceRefresh.subscribe(async () => await this.loadBalance());
    this.authService.triggerRoleRefresh.subscribe(async () => this.authService.UpdateRoleSubject());

    this.authService.triggerRoleRefresh.next();
    this._headerService.triggerBalanceRefresh.next();
  }

  private async loadBalance() {
    this.dataService.getBalanceMe()
      .subscribe(x => {
        this._headerService.balance.next(x);
      });
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<IBreadCrumb> = []): Array<IBreadCrumb> {
    // If no routeConfig is avalailable we are on the root path
    const label = route.routeConfig ? route.routeConfig.data.breadcrumb : 'Home';
    const path = route.routeConfig ? route.routeConfig.path : '/get-started';
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label,
      url: nextUrl,
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  // tslint:disable-next-line:typedef
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  // tslint:disable-next-line:typedef
  logout() {
    // this.router.navigate(['/login']);
    this.authService.Logout();
    window.location.reload();
  }

  RicaricaCredito() {

    const dialogRef = this.dialog.open(RedeemCodeDialogComponent, {
      width: '60%',
      height: '60%',
      data: {message: 'Riscatta un codice'},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result); // result will be true (confirmed) or false (canceled)
    });
  }
}
